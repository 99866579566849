import { module } from 'modujs';
import { animate, stagger } from 'motion';
import Splitting from 'splitting';
import { reducedMotion } from '../utils/environment';
import { isDefined } from '../utils/is';
import { raf, rafTimeout } from '../utils/raf';
import { map } from '../utils/math';
// import Vivus from 'vivus';

export default class extends module {
  constructor(m) {
    super(m);
  }

  init() {
    if (reducedMotion) return;
    
    this.type = this.el.dataset.reveal || 'scroll';
    // this.type = this.getData('anim') || 'scrollReveal';
    this.object = null;
    debug('init Reveal for', this.el, this.type);

    if (this.type === 'scroll' || this.type === 'block' || this.type === 'blend' || this.type === 'wipe') {
      this.object = new RevealScroll(this.el, this);
    } else if (this.type === 'title') {
      this.object = new RevealTitle(this.el, this);
    } else if (this.type === 'svg') {
      this.object = new RevealSVG(this.el, this);
    }
    // else if (this.type === 'dynamicScale') {
    //   this.object = new DynamicScale(this.el, this);
    // }

    // this.onEnter = this.onEnter.bind(this);
    // this.onLeave = this.onLeave.bind(this);
    // this.el.addEventListener('ScrollReveal:enter', this.onEnter);
    // this.el.addEventListener('ScrollReveal:leave', this.onLeave);

    // If this element is already in view
    // => launch reveal anim
    if ( this.el.classList.contains('is-inview') && this.object !== null ) {
      debug('already in view => reveal', this.el);
      // debug(this.object);
      this.object.reveal();
    }
  }

  onEnter(e) {
    // debug(e, this);
    if ( e.obj.el === this.el ) {
      // debug(e, this, this.object);

      if ( isDefined(this.object) && this.object !== null ) {
        if ( e.way === 'enter' ) {
          this.object.reveal();
        } else if ( e.way === 'exit' ) {
          this.object.leave();
        }
      }
    }
  }

  onProgress(progress) {
    this.object.progress = progress;
  }

  // Called when this elem enter viewport
  // onEnter(e) {
  //   this.object.reveal();
  // }

  // onLeave(e) {
  //   this.object.leave();
  // }

  destroy() {
    debug('destroy from Reveal.js', this.el);
    this.el.removeEventListener('ScrollReveal:enter', this.onEnter);
    // this.el.removeEventListener('ScrollReveal:leave', this.onLeave);
  }
}

class RevealScroll {
  constructor(el, mod) {
    this.el = el;
    this.classes = {
      start: 'reveal-animate',
      end: 'reveal-ended',
    };
    this.delay = parseInt(mod.getData('delay')) || 0;
    this.duration = 1200;
  }

  reveal() {
    debug('Reveal in', this.el);
    this.el.style.visibility = 'visible';

    rafTimeout(() => {
      this.el.classList.add(this.classes.start);

      rafTimeout(() => {
        this.el.classList.add(this.classes.end);
      }, this.duration);
    }, this.delay);

    // this.el.addEventListener(this.app.transitionEndEventName, this.onTransitionEnd);
  }

  leave() {}
}

class RevealTitle {
  constructor(el, mod) {
    this.el = el;
    this.DOM = {
      words: null,
      wordsText: null
    };
    this.delay = parseInt(mod.getData('delay')) || 0;

    // Get animation style
    this.anim = mod.getData('anim') || 'words-from-bottom'; // 'words-from-bottom', 'chars-from-bottom', 'words-rotate-x', 'chars-rotate-x', 'words-blur' or 'chars-blur'
    
    // Get split target
    // and how we should split
    let splitTarget = this.el;
    this.splitBy = mod.getData('splitby') || ( this.anim.indexOf('chars') !== -1 ? 'chars' : 'words' ); // 'chars' or 'words'
    
    if (mod.getData('splitchilds')) {
      splitTarget = this.el.children;
    }
    debug('split animation: ' + this.anim + ' | split by : ' + this.splitBy + ' | split target:', splitTarget);

    // And get the results of Splitting
    const results = Splitting({ target: splitTarget, by: this.splitBy });
    // debug(results);

    // Get words el
    this.DOM.words = this.el.querySelectorAll('.word');

    this.DOM.words.forEach(el => {
      if ( this.splitBy === 'chars' ) {
      }
      else {
        el.innerHTML = `<span class="word-text">${el.innerHTML}</span>`;
      }
    });

    // Get words text (= elems we will animate)
    if ( this.splitBy === 'chars' ) {
      this.DOM.wordsText = this.el.querySelectorAll('.char');
    }
    else {
      this.DOM.wordsText = this.el.querySelectorAll('.word-text');
    }

    this.animation = null;
  }

  setAnimation(direction) {
    this.duration = (direction === 'reverse') ? 0.01 : 1.2;

    if ( this.anim === 'words-from-bottom' || this.anim === 'chars-from-bottom' ) {

      // let baseColor = '#16171c';

      // From
      this.DOM.wordsText.forEach((item, i) => {
        // if (i === 0) baseColor = window.getComputedStyle(item).getPropertyValue( 'color' );
        item.style.transform = (direction === 'reverse') ? 'translate3d(0,0,0)' : 'translate3d(0,100%,0)';
        // item.style.color = '#e80a17';
      });
      // To animation
      this.animation = animate(this.DOM.wordsText, {
        transform: (direction === 'reverse') ? 'translate3d(0,100%,0)' : 'translate3d(0,0,0)',
        // color: baseColor,
      },{
        duration: this.duration,
        delay: stagger(0.05, { start: 0.2 }),
        easing: 'cubic-bezier(.165,.84,.44,1)',// out-quart
      });
      this.animation.pause();
      // Finish state
      this.animation.finished.then((e) => {
        this.DOM.wordsText.forEach(item => {
          item.style.removeProperty('transform');
          // item.style.removeProperty('color');
        });
        this.el.classList.add('reveal-ended');
      });

    } else if ( this.anim === 'words-rotate-x' || this.anim === 'chars-rotate-x' ) {
      
      // From
      this.DOM.wordsText.forEach(item => {
        item.style.transform = (direction === 'reverse') ? 'scale(1) rotate3d(0,0,0,-90deg) translate3d(0,0,0)' : 'scale(0.0001) rotate3d(1,0,0,-90deg) translate3d(0,0,0)';
        item.style.transformOrigin = 'bottom';
        item.style.opacity = (direction === 'reverse') ? 1 : 0;
      });
      // To animation
      this.animation = animate(this.DOM.wordsText, {
        opacity: (direction === 'reverse') ? 0 : 1,
        transform: (direction === 'reverse') ? 'scale(0.0001) rotate3d(1,0,0,-90deg) translate3d(0,0,0)' : 'scale(1) rotate3d(0,0,0,-90deg) translate3d(0,0,0)',
      },{
        duration: this.duration,
        delay: stagger(0.05, { start: 0.2 }),
        easing: 'cubic-bezier(.165,.84,.44,1)',// out-quart
      });
      this.animation.pause();
      // Finish state
      this.animation.finished.then((e) => {
        this.DOM.wordsText.forEach(item => {
          item.style.removeProperty('transform');
          item.style.removeProperty('transform-origin');
          item.style.removeProperty('opacity');
        });
        this.el.classList.add('reveal-ended');
      });

    } else if ( this.anim === 'words-blur' || this.anim === 'chars-blur' ) {

      // From
      this.DOM.wordsText.forEach((item, i) => {
        item.style.transform = (direction === 'reverse') ? 'translate3d(0,0,0)' : 'translate3d(10%,0,0)';
        item.style.opacity = (direction === 'reverse') ? 1 : 0;
        item.style.filter = (direction === 'reverse') ? 'blur(0)' : 'blur(10px)';
      });
      // To animation
      this.animation = animate(this.DOM.wordsText, {
        transform: (direction === 'reverse') ? 'translate3d(10%,0,0)' : 'translate3d(0,0,0)',
        filter: (direction === 'reverse') ? 'blur(10px)' : 'blur(0)',
        opacity: (direction === 'reverse') ? 0 : 1,
      },{
        duration: this.duration,
        delay: stagger(0.05, { start: 0.2 }),
        // easing: 'cubic-bezier(.165,.84,.44,1)',// out-quart
        // easing: 'cubic-bezier(0.770, 0.000, 0.175, 1.000)',// power3-in-out
        easing: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',// power2-in-out
      });
      this.animation.pause();
      // Finish state
      this.animation.finished.then((e) => {
        this.DOM.wordsText.forEach(item => {
          item.style.removeProperty('transform');
          item.style.removeProperty('opacity');
          item.style.removeProperty('filter');
        });
        this.el.classList.add('reveal-ended');
      });

    }
  }

  reveal() {
    debug('Reveal in Title', this.el);
    if (this.animation) this.animation.cancel();
    this.setAnimation('normal');
    
    rafTimeout(() => {
      this.el.style.visibility = 'visible';
      this.animation.play();
    }, this.delay);
  }

  leave() {
    debug('Leave out Title', this.el);
    if (this.animation) this.animation.cancel();
    this.el.classList.remove('reveal-ended');
    this.setAnimation('reverse');
    this.animation.play();
    this.animation.finished.then((e) => {
      this.el.style.visibility = 'hidden';
    });

    // rafTimeout(() => {
    //   this.el.style.visibility = 'hidden';
    // }, this.duration * 1000);
  }
}

class DynamicScale {
  constructor(el, mod) {
    this.el = el;
    this.img = el.querySelector('img');
    this.val = 6;
    // this.content = document.querySelector('#main > article');

    this.progress = 1;
    this.update = this.update.bind(this);
  }

  reveal() {
    debug('Start DynamicScale movement for', this.el);
    this.el.style.willChange = 'clip-path';
    this.img.style.willChange = 'transform';
    raf.on(this.update);
  }

  leave() {
    debug('Stop DynamicScale movement for', this.el);
    this.el.style.removeProperty('will-change');
    // this.el.style.removeProperty('clip-path');
    this.img.style.removeProperty('will-change');
    raf.off(this.update);
  }

  update() {
    let n = map(this.progress, 0, 0.5, 0, this.val);
    let t = map(this.progress, 0, 1, -this.val, this.val);
    this.el.style.clipPath = 'polygon(' + (this.val - n) + '% 0px, ' + ((100 - this.val) + n) + '% 0px, ' + ((100 - this.val) + n) + '% 100%, ' + (this.val - n) + '% 100%)';
    this.img.style.transform = 'translate3d(0,' + t + '%,0) scale3d(' + (1 + this.progress / 10) + ', ' + (1 + this.progress / 10) + ', 1)';
  }
}

class RevealSVG {
  constructor(el, mod) {
    let svg = el.querySelector('svg');
    this.isInit = false;
    this.DOM = {
      'svg': svg
    };

    if (svg) {
      // this.anim = new Vivus(svg, {
      //   start: 'manual',
      //   duration: 300,
      //   delay: parseInt(mod.getData('delay')) || 0,
      //   animTimingFunction: Vivus.EASE_OUT
      // });
      // debug(this.anim);

      this.isInit = true;
    }

    // // Init svg animation
    // let svgEls = [...document.querySelectorAll('.o-bg-svg')];
    // debug('init svg for', svgEls);

    // svgEls.forEach( el => {
    //   new Vivus(el, {
    //     start: 'autostart',
    //     duration: 1000
    //   });
    // });
  }

  reveal() {
    if (!this.isInit) return;
    // this.anim.play();
    this.DOM.svg.classList.add('-is-animating');
  }
  leave() {
    if (!this.isInit) return;
    // this.anim.stop();
    // this.anim.reset();
    this.DOM.svg.classList.remove('-is-animating');
  }
}