import { module } from 'modujs';

export default class extends module {
  constructor(m) {
    super(m);
  }

  init() {
    debug('init Fauxlink', this.el);

    this.el.addEventListener('click', (e) => {
      if (this.el.tagName === 'A') {
        return;
      }
  
      e.preventDefault();
  
      // let href = this.el.dataset.href;
      let href = this.getData('href');
      if (href === '' || href === '#') return;
  
      // If Command (macOS) or Ctrl (Windows) key pressed, stop processing
      // and open the image in a new tab
      if (e.metaKey || e.ctrlKey || this.el.getAttribute('target') || this.getData('target') === '_blank') {
        return window.open(href, '_blank');
      }
  
      // if ( _this.app.Load ) {
      //   _this.app.Load.H.redirect(href);
      //   return;
      // }
      // this.call('onGoTo', href, 'Load');
  
      return window.location = href;
      // return false;
    });
  }
}