import { module } from 'modujs';
// import LocomotiveScroll from 'locomotive-scroll'; // https://github.com/locomotivemtl/locomotive-scroll
// import { lazyLoadImage } from '../utils/image';
import { body, reducedMotion, isMobile, isAdmin } from '../utils/environment';
import { resize } from '../utils/resize';
import { translate, transform } from '../utils/transform';
import { isUndefined } from '../utils/is';
import { lerp, map } from '../utils/math.js';

// https://github.com/quentinhocde/loconative-scroll

// Create Events
// https://developer.mozilla.org/fr/docs/Web/Guide/DOM/Events/Creating_and_triggering_events
// const events = {
//   'enter': document.createEvent('Event'),
//   'leave': document.createEvent('Event'),
// };
// events.enter.initEvent('ScrollReveal:enter', true, true);
// events.leave.initEvent('ScrollReveal:leave', true, true);


export default class extends module {
  constructor(m) {
    super(m);

    this.events = {
      click: {
        top: 'scrollToTop',
        content: 'scrollToContent',
        to: 'scrollToTarget'
      }
    }

    this.scrollToOptions = {
      duration: 1,
      offset: -100
    }

    this.dynamicScaleElems = [];
  }

  init() {
    if (reducedMotion || isAdmin) return;
    debug('init from Scroll.js', this.el);

    this.oldY = 0;
    this.speed = 0;
    this.maxSpring = 150;
    this.hasScroll = false;
    this.direction = '';

    debug('initial scroll pos', window.scrollY);

    // Init SmoothScroll
    // this.scroll = new LocomotiveScroll({
    this.scroll = new LoconativeScroll({
      el: this.el,
      smooth: isAdmin ? false : true,
      lerp: 0.2, // 0.1
      // initPosition: { x: 0, y: window.scrollY },
      firefoxMultiplier: 50,
      touchMultiplier: 3, // 2
      // resetNativeScroll: false,

      // tablet: {
      //   smooth: true,
      //   // direction: 'vertical',
      //   // gestureDirection: 'vertical',
      //   // breakpoint: 1024,
      // },
      // smartphone: {
      //   smooth: true,
      // }
    });

    this.scroll.on('call', (func, way, obj, id) => {
      // Using modularJS
      this.call(func[0], { way, obj }, func[1], func[2]);
    });

    this.scroll.on('scroll', (args) => {
      // debug(args.scroll);
      // debug(args);
      let y = args.scroll.y;
      this.speed = (y - this.oldY) * 0.1;
      this.oldY = y;
      // debug(this.speed);

      // Scroll direction
      if (args.direction !== this.direction) {
        if (args.direction === 'up') body.classList.remove('-scrolling-down');
        if (args.direction === 'down') body.classList.remove('-scrolling-up');
        body.classList.add('-scrolling-' + args.direction);
      }

      // Has scroll ?
      if (args.scroll.y > 50) {
        if (!this.hasScroll) {
          this.hasScroll = true;
          body.classList.add('has-scroll');
        }
      } else {
        if (this.hasScroll) {
          this.hasScroll = false;
          body.classList.remove('has-scroll');
          // this.call('close', null, 'Navigation');
        }
      }

      this.call('onScroll', args.scroll.y, 'Sketch');

      this.onScroll(args.currentElements);

      // if(typeof args.currentElements['hey'] === 'object') {
      //   let progress = args.currentElements['hey'].progress;
      //   debug('progress: ' + progress, args.currentElements['hey']);
      //   // ouput log example: 0.34
      //   // gsap example : myGsapAnimation.progress(progress);
      // }
    });

    // window.onload = (event) => {
    //   this.update();
    // }
    // setTimeout(() => {
    //   // debug('Scroll set scroll limit');
    //   // this.scroll.scroll.setScrollLimit();
    //   this.update();
    // }, 1000);

    this.update = this.update.bind(this);
    resize.on(this.update);

    // Ancre
    this.initAncreLink();

    // On scroll
    this.onScroll(this.scroll.scroll.currentElements);
  }

  onScroll(currentElements) {
    // Get all current elements
    for (const [k, o] of Object.entries(currentElements)) {
      // debug(o);
      // debug('progress: ' + o.progress, o);

      if (o.el.dataset.scrollType !== undefined && ( o.el.dataset.scrollType === 'parallax' || o.el.dataset.scrollType === 'inner-parallax' )) {
        this.setParallax(o);
      }
      if (o.el.dataset.scrollType !== undefined && o.el.dataset.scrollType === 'spring') {
        this.setSpring(o);
      }
      if (o.el.dataset.scrollType !== undefined && o.el.dataset.scrollType === 'image-scale') {
        this.setImageScale(o);
      }
      if (o.el.dataset.scrollType !== undefined && o.el.dataset.scrollType === 'mask-scale') {
        this.setMaskScale(o);
      }
      if (o.el.dataset.scrollType !== undefined && o.el.dataset.scrollType === 'skew') {
        this.setSkew(o);
      }

      if (o.el.dataset.reveal !== undefined && o.el.dataset.reveal === 'dynamicScale') {
        this.call('onProgress', o.progress, 'Reveal');
      }
    }
  }

  update() {
    debug('update all element positions', this.scroll.scroll);
    // console.warn(this.el.offsetHeight);
    this.scroll.update();
  }

  scrollToTop(e) {
    debug('scroll to top fired !');
    this.scroll.scrollTo('top', this.scrollToOptions);
  }
  scrollToContent(e) {
    debug('scroll to content fired !');
    this.scroll.scrollTo(document.querySelector('.wrapper'), this.scrollToOptions);
  }
  scrollToTarget(e) {
    debug('scroll to target fired !', e.currentTarget);
    // Get href of this link
    // or get the data-scrollto string
    const target = document.querySelector( e.currentTarget.getAttribute('href') ? e.currentTarget.getAttribute('href') : e.currentTarget.dataset.scrollto );
    if ( target ) {
      debug('scroll to target', target);
      this.scroll.scrollTo(target, this.scrollToOptions);
    }
  }
  scrollto(target) {
    if ( target ) {
      debug('scroll to target', target);
      this.scroll.scrollTo(target, this.scrollToOptions);
    }
  }

  // onEnter(e) {
  //   let el = e.obj.el;
  //   debug('inview trigger : ' + e.way, el)

  //   if (e.way === 'enter') {
  //     el.dispatchEvent(events.enter);
  //   } else if (e.way === 'exit') {
  //     el.dispatchEvent(events.leave);
  //   }
  // }


  // Ancre Link
  // Init link with #idOfAnElement
  // => scroll to this element
  initAncreLink() {
    const linksAncre = Array.from( document.querySelectorAll('a[href*="#"]:not(#cn-accept-cookie):not(#cn-refuse-cookie):not(.c-back-to-top):not(.tabs__link):not(.u-screen-reader-text):not(.screen-reader-shortcut)') );
    debug('init Ancre link for', linksAncre);

    // Ancre hash in url
    const windowHash = window.location.hash;
    if (windowHash !== '') {
      debug('has hash', windowHash);
      const hash_target = document.querySelector( windowHash );
      
      setTimeout(() => {
        if (hash_target) {
          debug('scroll to target', hash_target);
          this.scroll.scrollTo(hash_target, this.scrollToOptions);
        }
      }, 150);
    }

    // Add Event Listener to all links
    linksAncre.forEach( link => this.initAncreClickForElem(link) );
  }
  initAncreClickForElem(el) {
    el.addEventListener('click', (e) => {
      const id = e.target.getAttribute('href') ? e.target.getAttribute('href').substring(e.target.getAttribute('href').indexOf('#')) : e.target.dataset.scrollto;
      const target = document.querySelector( id );
      if ( target ) {
        e.preventDefault();
        debug('scroll to target', target);
        this.scroll.scrollTo(target, this.scrollToOptions);
      }
      return false;
    });
  }


  // SCROLL ANIMATIONS

  // Parallax
  setParallax(o) {
    let fromVal   = parseFloat(o.el.dataset.scrollFrom) || 0,
        toVal     = parseFloat(o.el.dataset.scrollTo) || fromVal * -1;
    const unit      = o.el.dataset.scrollUnit || 'px',
          direction = o.el.dataset.scrollDirection || 'horizontal';

    if (isMobile) {
      fromVal = fromVal / 2;
      toVal = toVal / 2;
    }
    let val = map(o.progress, 0, 1, fromVal, toVal);
    let x = val,
        y = 0;
    if (direction === 'vertical') {
      x = 0;
      y = val;
    }

    // console.log(o, x, y, unit);
    if (o.el.dataset.scrollType === 'inner-parallax') {
      let img = o.el.querySelector('img');
      if (img) {
        translate(img, x, y, unit);
      }
    } else {
      translate(o.el, x, y, unit);
    }
  }

  // Spring
  setSpring(o) {
    if (isMobile) return;
    if (isUndefined(o.spring)) {
      console.log('new spring object', o);
      o.spring = {
        current: 0,
        target: 0,
        ease: 0.1
      };
    }

    o.spring.target = Math.min(this.speed * 20.0, this.maxSpring);
    o.spring.current = lerp(
      o.spring.current,
      o.spring.target,
      o.spring.ease
    );
    translate(o.el, 0, o.spring.current, 'px');
  }
  
  // Image Scale
  setImageScale(o) {
    const fromVal = parseFloat(o.el.dataset.scrollFrom) || 1,
          toVal   = parseFloat(o.el.dataset.scrollTo) || 1.2,
          val     = map(o.progress, 0, 1, fromVal, toVal),
          img     = o.el.querySelector('img');
    
    if (img) {
      transform(img, `scale3d(${val},${val},1)`);
    }
  }

  // Skew
  setSkew(o) {
    if (isMobile) return;
    if (isUndefined(o.skew)) {
      console.log('new skew object', o);
      o.skew = {
        current: 0,
        target: 0,
        ease: 0.1
      };
    }

    o.skew.target = this.speed * 2.5;
    o.skew.current = lerp(
      o.skew.current,
      o.skew.target,
      o.skew.ease
    );
    transform(o.el, `skewY(${o.skew.current}deg)`);
  }

  // Mask Scale
  setMaskScale(o) {
    const diff = 6,
          n    = map(o.progress, 0, 0.5, 0, diff),
          t    = map(o.progress, 0, 1, -diff, diff),
          s    = 1 + (o.progress / 10),
          img  = o.el.querySelector('img');
    
    o.el.style.clipPath = `polygon(${diff - n}% 0px, ${(100 - diff) + n}% 0px, ${(100 - diff) + n}% 100%, ${diff - n}% 100%)`;
    if (img) {
      transform(img, `translate3d(0,${t}%,0) scale3d(${s},${s},1)`);
    }
  }

  /**
   * Lazy load the related image.
   *
   * @see ../utils/image.js
   *
   * It is recommended to wrap your `<img>` into an element with the
   * CSS class name `.c-lazy`. The CSS class name modifier `.-lazy-loaded`
   * will be applied on both the image and the parent wrapper.
   *
   * ```html
   * <div class="c-lazy o-ratio u-4:3">
   *     <img data-scroll data-scroll-call="lazyLoad, Scroll, main" data-src="http://picsum.photos/640/480?v=1" alt="" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" />
   * </div>
   * ```
   *
   * @param {LocomotiveScroll} args - The Locomotive Scroll instance.
   */
  // lazyLoad(args) {
  //   lazyLoadImage(args.obj.el, null, () => {
  //     //callback
  //   })
  // }

  destroy() {
    debug('destroy from Scroll.js');
    
    // Reset scroll position
    // (for example, can be called when navigating between pages)
    // this.scroll.reset();

    this.scroll.destroy();
  }
}