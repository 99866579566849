import { listen } from 'quicklink';
import { html, body, isIE, isMobile, isAdmin, intersectionObserverSupport, cssMixBlendMode, connection, reducedMotion } from './utils/environment';
import { resize } from './utils/resize';
import { isDefined } from './utils/is';
import { rafTimeout } from './utils/raf';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';
// import Swiper from 'swiper/bundle';
import Typed from 'typed.js';

export function globals() {
  debug('init globals');

  // Classes from environment
  html.classList.add(isIE ? 'is-ie' : 'is-not-ie');
  html.classList.add(isMobile ? 'is-mobile' : 'is-not-mobile');
  html.classList.add(cssMixBlendMode ? 'has-mix-blend-mode' : 'has-not-mix-blend-mode');
  html.classList.add(intersectionObserverSupport ? 'has-intersection-observer' : 'has-not-intersection-observer');
  html.classList.add(reducedMotion ? 'has-reduced-motion' : 'has-not-reduced-motion');

  // debug(window.matchMedia('(prefers-reduced-motion: reduce)').matches);
  // const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {};
  // if (connection.saveData || ['slow-2g', '2g', '3g'].includes(connection.effectiveType)) {
  //   // prevent loading resources and other data saving measures
  // }

  // Inits
  initSliders();
  if (!isAdmin) {
    initPageTransition();
    // initQuicklink();
    // initFauxLink();
    responsiveEmbeds();
    resize.on(responsiveEmbeds);
  }

  // Close mobile menu
  if (isMobile && !isAdmin) {
    const links = [
      ...document.querySelectorAll('#primary-menu a'),
      ...document.querySelectorAll('.site-header .header-widget a')
    ];
    const menuToogle = document.querySelector('.site-header .menu-toggle');
    links.forEach((l) => {
      l.addEventListener('click', () => {
        menuToogle.click();
      });
    });
  }
}

export function onPageLoaded() {
  debug('init onPageLoaded');

  // Inits
  initTyped();
  if (!isAdmin) {
    initReducedMotion();
    
    // Contact Form 7
    const handleForm = function(form, e) {
      debug('wpcf7 form mail sent');
      const output = form.querySelector('.wpcf7-response-output');
      debug(output);
      if (output) {
        output.style.display = 'none';
        
        setTimeout(() => {
          const clone = output.cloneNode();
          clone.innerHTML = output.innerHTML;
          clone.style.display = 'flex';
          body.appendChild(clone);
          debug(clone);
  
          setTimeout(() => {
            clone.classList.add('-is-visible');
          }, 50);
  
          setTimeout(() => {
            clone.classList.remove('-is-visible');
  
            setTimeout(() => {
              clone.remove();
            }, 650);
          }, 5000);
        }, 250);
      }
    }
    var wpcf7Elm = document.querySelector( '.wpcf7' );
    wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
      handleForm(wpcf7Elm, event);
    }, false );
    wpcf7Elm.addEventListener( 'wpcf7mailfailed', function( event ) {
      handleForm(wpcf7Elm, event);
    }, false );
  }
}


// Init Quicklink
// => prefetch in-viewport links during idle time
// https://github.com/GoogleChromeLabs/quicklink
// Only listen to given links
function initQuicklink() {
  if (!intersectionObserverSupport ) {
    return
  }

  let options = isDefined(_quicklinkOptions) ? _quicklinkOptions : {
    el: body,
    // origins: [
    //   site_baseurl,
    //   // site_url.replace(/(^\w+:|^)\/\//, '')
    // ], // default to : [location.hostname]
    ignores: [
      /\/api\/?/,
      /\/wp-admin\/?/,
      /\/wp-login\/?/,
      uri => uri.includes('.zip'),
      uri => uri.includes('#'),
      (uri, elem) => elem.hasAttribute('noprefetch')
    ]
  };
  options.ignores.push(uri => uri.includes('#')); // Don't prefetch url that contains #
  options.ignores.push((uri, elem) => elem.hasAttribute('noprefetch')); // Don't prefetch elem that contains noprefetch attribute
  
  listen(options);
}


// Init Page transition
// = click on link
// => anim the content to fade out / transform
function initPageTransition() {
  let current_url = window.location.href;
  if (current_url.indexOf('#') !== -1) {
    current_url = current_url.substring( 0, current_url.indexOf('#') );
  }
  // debug('current url is ' + current_url);
  
  [...document.querySelectorAll('a')].forEach((a) => {
  
    if (a.classList.contains('no-ajax')) {
    } else {
      const url = a.getAttribute('href');
      let url_withtout_hash = url;
      let url_has_hash = url.indexOf('#') !== -1;
      if (url_has_hash) {
        url_withtout_hash = url.substring( 0, url.indexOf('#') );
      }

      if (url && url.indexOf(site_url) === 0 && url.indexOf('.zip') === -1 && url.indexOf('/wp-admin') === -1)
      {
        // debug('link url is', url, url_withtout_hash);
        // debug(url_withtout_hash === current_url ? 'same url' : 'different url');

        if (!url_has_hash || (url_has_hash && url_withtout_hash !== current_url)) {
          a.addEventListener('click', (e) => {
            // If Command (macOS) or Ctrl (Windows) key pressed, stop processing
            if (e.metaKey || e.ctrlKey) {
              // return w.open(element.src, '_blank');
              return;
            }
  
            e.preventDefault()
  
            html.classList.remove('is-ended');
            html.classList.add('is-leaving');
            html.classList.remove('is-ready');
            rafTimeout(() => {
              window.location = url;
            }, 300);
          });
        }
      }
    }
  });
}


// Init Sliders
function initSliders() {
  let sliders = [...document.querySelectorAll('.c-slider')];
  sliders.forEach( el => initSliderForElem(el) );
}
function initSliderForElem(el) {
  const opt = {
    // Optional parameters
    direction: el.dataset.swiperDirection || 'horizontal', // 'horizontal' | 'vertical'
    loop: el.dataset.swiperLoop === 'false' ? false : true,
    speed: parseInt(el.dataset.swiperSpeed) || 600,
    spaceBetween: parseInt(el.dataset.swiperSpaceBetweenMobile) || (parseInt(el.dataset.swiperSpaceBetween) / 2 || 0),
    slidesPerView: parseInt(el.dataset.swiperSlidesPerViewMobile) || 1,
    grabCursor: true,
    effect: el.dataset.swiperEffect || 'slide',

    breakpoints: {
      // when window width is >= 600px
      600: {
        spaceBetween: parseInt(el.dataset.swiperSpaceBetweenTablet) || (parseInt(el.dataset.swiperSpaceBetweenMobile) || (parseInt(el.dataset.swiperSpaceBetween) / 2 || 0)),
        slidesPerView: parseInt(el.dataset.swiperSlidesPerViewTablet) || (parseInt(el.dataset.swiperSlidesPerView) || 1),
      },
      // when window width is >= 1000px
      1000: {
        spaceBetween: parseInt(el.dataset.swiperSpaceBetween) || 0,
        slidesPerView: parseInt(el.dataset.swiperSlidesPerView) || 1,
      }
    },
    
    // configure Swiper to use modules
    modules: [Navigation, Pagination, Autoplay, EffectFade],
  };
  let hasAutoplay = false;

  // Autoplay
  if ( el.dataset.swiperAutoplay == '1' || el.dataset.swiperAutoplay == 'true' ) {
    hasAutoplay = true;
    opt.autoplay = {
      delay: el.dataset.swiperAutoplayDelay || 5000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    }
  }

  // Navigation arrows
  if ( el.querySelector('.swiper-button-next') ) {
    opt.navigation = {
      nextEl: el.querySelector('.swiper-button-next'),
      prevEl: el.querySelector('.swiper-button-prev'),
    }
  }

  // Pagination
  if ( el.querySelector('.swiper-pagination') ) {
    opt.navigation = {
      el: el.querySelector('.swiper-pagination'),
      type: el.dataset.swiperPaginationType || 'bullets', // 'bullets' | 'fraction' | 'progressbar' | 'custom'
    }
  }

  // Effect Fade
  if ( el.dataset.swiperEffect === 'fade' ) {
    opt.fadeEffect = {
      crossFade: true
    };
  }

  debug('init swiper', el, opt);
  const swiper = new Swiper(el, opt);

  if (hasAutoplay) {
    swiper.autoplay.pause();

    // Add an observer to pause / run the autoplay
    let observer;

    let options = {
      // root: document,
      rootMargin: '0px',
      threshold: 0.0
    }

    let callback = (entries, observer) => {
      entries.forEach((entry) => {
        // Each entry describes an intersection change for one observed
        // target element:
        //   entry.boundingClientRect
        //   entry.intersectionRatio
        //   entry.intersectionRect
        //   entry.isIntersecting
        //   entry.rootBounds
        //   entry.target
        //   entry.time
        // let elem = entry.target;

        if (entry.isIntersecting) {
          // console.log('is enter viewport', elem);
          swiper.autoplay.run();
        } else {
          // console.log('is leaving viewport', elem);
          swiper.autoplay.pause();
        }
      });
    };

    observer = new IntersectionObserver(callback, options);
    observer.observe(el);
  }
}


// Init Typed.js elems
function initTyped() {
  // Wait for title reveal animation
  setTimeout(() => {

    let typedEls = [...document.querySelectorAll('.js-typed')];
    debug('init typed.js for', typedEls);

    typedEls.forEach( el => {
      // let words = el.dataset.words;
      let words = el.dataset.words;
      if (words) {
        words = words.split(',');
        debug('with words', words);

        // create a new div element
        el.style.minHeight = el.offsetHeight + 'px';
        el.innerHTML = '<span class="js-typed-content"></span>';
        const typedEl = el.querySelector('.js-typed-content');

        let typed = new Typed(typedEl, {
          strings: words,
          typeSpeed: 60,
          backSpeed: 60,
          // smartBackspace: true,
          // shuffle: false,
          backDelay: 1000,
          loop: true,
          // loopCount: Infinity,
        });
      }
    } );

  }, 1500);
}


// // Faux link
// // Click on the elem redirect to the data-href location
// function initFauxLink() {
//   let fauxLink = [...document.querySelectorAll('.o-faux-link')];
//   fauxLink.forEach( link => initFauxLinkForElem(link) );
// }
// function initFauxLinkForElem(el) {
//   // let _this = this;
//   el.addEventListener('click', (e) => {
//     if (e.target.tagName === 'A') {
//       return;
//     }

//     e.preventDefault();

//     let href = el.dataset.href;
//     if (href === '' || href === '#') return;

//     // If Command (macOS) or Ctrl (Windows) key pressed, stop processing
//     // and open the image in a new tab
//     if (e.metaKey || e.ctrlKey || e.target.getAttribute('target')) {
//       return window.open(href, '_blank');
//     }

//     // if ( _this.app.Load ) {
//     //   _this.app.Load.H.redirect(href);
//     //   return;
//     // }
//     if ( _app ) {
//       _app.call('onGoTo', href, 'Load');
//     }

//     return window.location = href;
//   });
// }


// Reduced Motion
function initReducedMotion() {
  // https://polypane.app/blog/creating-websites-with-prefers-reduced-data/
  const videos = [...document.querySelectorAll('video')];

  // default to save data being on
  const saveData = connection.saveData || true;
  const slowConnection = ['slow-2g', '2g', '3g'].includes(connection.effectiveType);

  // test if there is no preference, then also check if saveData is not on
  const canAutoPlayAndPreload = !reducedMotion || !saveData || !slowConnection;

  // debug('saveData', saveData);
  // debug('slowConnection', slowConnection);
  // debug('canAutoPlayAndPreload', canAutoPlayAndPreload);

  videos.forEach(video => {
    video.setAttribute('autoplay', canAutoPlayAndPreload);
    video.setAttribute('preload', canAutoPlayAndPreload);

    // if (reducedMotion) {
      // video.pause();
    // }
  });
}


/**
 * Make embeds responsive so they don't overflow their container.
 * Add max-width & max-height to <iframe> elements, depending on their width & height props.
 *
 * @return {void}
 */
function responsiveEmbeds() {
  var proportion, parentWidth;

  // Loop iframe elements.
  document.querySelectorAll( 'iframe' ).forEach( function( iframe ) {
    // Only continue if the iframe has a width & height defined.
    if ( iframe.width && iframe.height ) {
      // Calculate the proportion/ratio based on the width & height.
      proportion = parseFloat( iframe.width ) / parseFloat( iframe.height );
      // Get the parent element's width.
      parentWidth = parseFloat( window.getComputedStyle( iframe.parentElement, null ).width.replace( 'px', '' ) );
      // Set the max-width & height.
      iframe.style.maxWidth = '100%';
      iframe.style.maxHeight = Math.round( parentWidth / proportion ).toString() + 'px';
    }
  } );
}
