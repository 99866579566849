// export {default as Example} from './modules/Example';
// export {default as Pageloader} from './modules/Pageloader';
// export {default as Load}       from './modules/Load';
// export {default as Navigation} from './modules/Navigation';
export {default as Scroll}     from './modules/Scroll';
// export {default as Sketch}     from './modules/Sketch';
// export {default as Glimage}    from './modules/Glimage';
// export {default as Cursor}     from './modules/Cursor';
export {default as Fauxlink}   from './modules/Fauxlink';
// export {default as Lightbox}   from './modules/Lightbox';
export {default as Reveal}     from './modules/Reveal';
// export {default as Spring}     from './modules/Spring';
// export {default as Accordion}  from './modules/Accordion';


// // export { Example }        from '@modules/example';
// export { Globals }        from '@modules/globals';
// // export { Page }           from '@modules/page';
// export { PageLoader }     from '@modules/pageLoader';
// export { BackToTop }      from '@modules/back-to-top';
// export { Pointer }        from '@modules/pointer';
// export { Cursor }         from '@modules/cursor';
// export { Cookie }         from '@modules/cookie';
// export { Navigation }     from '@modules/navigation';
// export { SmoothScroll }   from '@modules/smoothscroll';
// export { RevealOnScroll } from '@modules/revealOnScroll';
// export { Load }           from '@modules/load';
// export { HoverList }      from '@modules/hoverList';
// // export { ImageTrail }     from '@modules/imageTrail';
// export { Title }          from '@modules/title';
// export { Circles }          from '@modules/circles';
// // export { Image }          from '@modules/image';
// // export { Gallery }        from '@modules/gallery';
// export { Lightbox }       from '@modules/lightbox';
// export { TeamList }       from '@modules/teamlist';
// export { Gooey }       from '@modules/gooey';
// export { Bubbles }       from '@modules/bulles';