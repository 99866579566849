import Snif from './snif';

const APP_NAME     = 'Nukk.AI';
// const DATA_API_KEY = '.data-api';
const GA_CODE      = 'UA-XXXXXXXX-X';

const html         = document.documentElement;
const body         = document.body;

const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {};

const isIE         = Snif.isIE;
const isMobile     = Snif.isMobile;
const isDebug      = html.hasAttribute('data-debug');
const isAdmin      = body.classList.contains('wp-admin') || body.classList.contains('login');

const transitionEndEventName = getTransitionEndEventName();
const cssVariablesSupport = window.CSS && CSS.supports('color', 'var(--fake-var)');
const cssMixBlendMode     = window.CSS && CSS.supports('mix-blend-mode','multiply');

// const webpSupport              = Util.hasClass( document.documentElement, 'webp' );
const intersectionObserverSupport = "IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype;
const mutationObserverSupport     = "MutationObserver" in window;
const idleCallbackSupport         = "requestIdleCallback" in window;
const reducedMotion               = window.matchMedia('(prefers-reduced-motion: reduce)').matches;


function getTransitionEndEventName() {
  var transitions = {
    "transition"      : "transitionend",
    "OTransition"     : "oTransitionEnd",
    "MozTransition"   : "transitionend",
    "WebkitTransition": "webkitTransitionEnd"
   }
  let bodyStyle = document.body.style;
  for(let transition in transitions) {
    if (bodyStyle[transition] != undefined) {
      return transitions[transition];
    } 
  }
}


export {
  APP_NAME,
  // DATA_API_KEY,
  GA_CODE,

  html, body,

  connection,

  isIE, isMobile, isDebug, isAdmin,

  cssVariablesSupport, cssMixBlendMode,
  transitionEndEventName,
  
  intersectionObserverSupport, mutationObserverSupport,
  idleCallbackSupport, reducedMotion
};