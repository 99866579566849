import modular from 'modujs';
import * as modules from './modules';
import { globals, onPageLoaded } from './globals';
import { html, isAdmin } from './utils/environment';
import debug from './utils/debug';

window.debug = debug;

// Init modujs
const app = new modular({
  modules: modules
});
window._app = app;

// // Page loader
// const pageloader = document.querySelector('.c-pageloader');
// debug('sessionStorage', sessionStorage.getItem('skipIntro'));
// if (!sessionStorage.getItem('skipIntro')) {
//   pageloader.classList.add('is-visible');
// }

function initialize() {
  const $style = document.getElementById('critical-css');

  if ($style) {
    if ($style.isLoaded) {
      init();
    } else {
      $style.addEventListener('load', (event) => {
        init();
      });
    }
  } else {
    console.warn('The "critical-css" stylesheet not found.');
  }
}

function init() {
  if (isAdmin) return;
  debug('init the app');
  html.classList.remove('is-loading');

  globals();

  // Start pageloader anim out
  html.classList.add('is-ready');
  html.classList.add('is-loaded');
  
  setTimeout(() => {
    
    // Pageloader is out
    // => init the app
    // html.classList.add('is-ended');
    app.init(app);
    onPageLoaded();

  }, 650);
}

// Window loaded
if (window._is_loaded) {
  debug('window already loaded');
  initialize();
} else {
  window.onload = (event) => {
    debug('on window loaded');
    initialize();
  };
}

// If the cache is loaded via bfcache (back/forward cache)
// https://web.dev/bfcache/
window.addEventListener('pageshow', (event) => {
  if (event.persisted) {
    debug('This page was restored from the bfcache.');
    initialize();
  } else {
    // console.log('This page was loaded normally.');
  }
});
